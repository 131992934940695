import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { ExternalTokenModel } from '../+auth/models/external-token.model';
import { Token } from '../+auth/models/token.model';
import { Customer } from '../shared/models/customer-model';
import { EncryptionUtils } from './utils/encryption-utils';

export class StoreHelper {
  constructor(public _router: Router) { }

  public static clearAllLocalAndSessionStorage() {
    localStorage.removeItem('tk');
    localStorage.removeItem('CurUser');
    localStorage.removeItem('cci_');
    localStorage.removeItem('cci');
    localStorage.removeItem('cc');
    localStorage.removeItem('tm');
    document.cookie = 'external=';
  }

  public static get lang(): string {
    return localStorage.getItem('lang');
  }

  public static set lang(lang: string) {
    localStorage.setItem('lang', lang);
  }

  public static set token(token: Token) {
    localStorage.setItem('tk', EncryptionUtils.encryptStr(JSON.stringify(token)));
  }

  public static get token(): Token {
    try {
      const tokenStr = localStorage.getItem('tk');
      return !isNullOrUndefined(tokenStr) && tokenStr !== '' ? JSON.parse(EncryptionUtils.descryptStr(tokenStr)) as Token : new Token();
    } catch (error) {
      return new Token();
    }
  }

  public static get tokenModel(): ExternalTokenModel {
    try {
      const token = JSON.parse(localStorage.getItem('tm')) as ExternalTokenModel;
      return !isNullOrUndefined(token) ? token : new ExternalTokenModel();
    } catch (error) {
      return new ExternalTokenModel();
    }
  }

  public static set tokenModel(tokenModel: ExternalTokenModel) {
    localStorage.setItem('tm', JSON.stringify(tokenModel));
  }

  public static get userInformation(): string {
    return localStorage.getItem('CurUser');
  }

  public static set userInformation(currentUser: string) {
    localStorage.setItem('CurUser', currentUser);
  }

  public static get currentCustomerId(): string {
    if (StoreHelper.ccLocalHasValue()) {
      return localStorage.getItem('cci');
    }
  }

  public static set currentCustomerId(cci: string) {
    localStorage.setItem('cci', cci);
  }

  public static get currentCustomer(): Customer {
    try {
      if (StoreHelper.ccLocalHasValue()) {
        return JSON.parse(localStorage.getItem('cc')) as Customer;
      }
    } catch (error) {
      console.error('currentCustomer->', error);
    }
  }

  public static set currentCustomer(cc: Customer) {
    const customer = JSON.stringify(cc);
    localStorage.setItem('cc', customer);
  }

  public static get customerList(): any {
    return JSON.parse(localStorage.getItem('cci_'));
  }

  public static set customerList(customerList: any) {
    if (customerList !== null && customerList !== undefined && customerList !== '') {
      localStorage.setItem('cci_', JSON.stringify(customerList));
    } else {
      localStorage.removeItem('cci_');
    }
  }

  public static willClearLocalAndSessionStorage(): void {
    if (!StoreHelper.storagesHasValue()) {
      StoreHelper.clearAllLocalAndSessionStorage();
    }
  }

  public static storageKeyHasValue(storageObj: any, key: string): boolean {
    const value = storageObj.getItem(key);
    return !isNullOrUndefined(value) && value !== '' && value !== 'null' && value !== 'undefined';
  }

  public static storagesHasValue(): boolean {
    if (localStorage.length === 0) { return false; }
    return StoreHelper.storageKeyHasValue(localStorage, 'tk');
  }

  public static ccLocalHasValue() {
    return StoreHelper.storageKeyHasValue(localStorage, 'cc');
  }

  public static set setReleaseVersion(releaseVersion: string) {
    localStorage.setItem('releaseVersion', releaseVersion);
  }

  public static get getReleaseVersion(): string {
    return localStorage.getItem('releaseVersion');
  }

  public static setChangeFilterSearch(isChange: string) {
    sessionStorage.setItem('changeFilterSearch', isChange);
  }

  public static getChangeFilterSearch() {
    return sessionStorage.getItem('changeFilterSearch');
  }

  public static clearLocalAndSessionStorage() {
    StoreHelper.clearAllLocalAndSessionStorage();
  }
}
