import { HttpHeaders } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { LanguageProvider } from '../../../providers/language.provider';
import { StoreHelper } from '../../store-helper';
import { TokenValidationUtils } from '../../utils/token-validation-utils';
import { ExternalTokenModel } from 'app/+auth/models/external-token.model';
import { Token } from 'app/+auth/models/token.model';
import { CurrentUser } from '../../../shared/models/current-user.model';

export class HeadersHelper {
    public static readonly headerList: string[] = ['authorization', 'timezoneoffset', 'emailcseplatform', 'cci', 'content-type', 'tokenvalid', 'platformlanguage'];

    public static createHeaders(headers: HttpHeaders): HttpHeaders {
        headers = HeadersHelper.createAuthorizationHeader(headers);
        headers = HeadersHelper.createLanguageHeader(headers);
        return headers;
    }

    public static createAuthorizationHeader(headers: HttpHeaders): HttpHeaders {
        const externalToken: ExternalTokenModel = StoreHelper.tokenModel;
        let accessToken = externalToken.token;
        const tokenIsNull = isNullOrUndefined(accessToken);
        const email = StoreHelper.token.username;
        const token: Token = StoreHelper.token;
        const currentUser = StoreHelper.userInformation ? <CurrentUser>JSON.parse(StoreHelper.userInformation) : null;
        if ((!isNullOrUndefined(token)) && tokenIsNull) {
            accessToken = token.token;
        }
        if (accessToken !== null && accessToken !== undefined) {
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
            headers = headers.set('TimezoneOffset', new Date().getTimezoneOffset().toString());
        }
        if (!isNullOrUndefined(email)) {
            headers = headers.set('EmailCsePlatform', email);
        }
        const cci = StoreHelper.currentCustomerId;
        if (cci !== null && cci !== undefined) {
            headers = headers.set('CCI', cci);
        }
        if (!headers.has('Content-Type')) {
            headers = headers.set('Content-Type', 'application/json');
        }
        if (currentUser) {
          headers = headers.set('CurrentUserId', currentUser.id);
        }
        headers = TokenValidationUtils.tokenIsValid(headers, token);
        return headers;
    }

    public static createLanguageHeader(headers: HttpHeaders): HttpHeaders {
        const language = LanguageProvider.getDefaultLanguage();
        if (language !== null && language !== undefined) {
            headers = headers.set('PlatformLanguage', language);
        } else {
            headers = headers.set('PlatformLanguage', 'pt');
        }
        return headers;
    }
}
